<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="ease-out transition-medium"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-out transition-medium"
    leave-to-class="opacity-0"
  >
    <div
      @keydown.esc="isOpen = false"
      v-show="showBackdrop"
      class="fixed inset-0 transition-opacity"
    >
      <div
        @click="isOpen = false"
        class="absolute inset-0 bg-black opacity-50"
        tabindex="0"
      ></div>
    </div>
  </transition>

  <div
    class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    "
  >
    <div class="flex flex-wrap item-center justify-between m-2 mb-6">
      <h1 class="text-xl text-left font-medium text-gray-800">Alunos</h1>
      <button
        :disabled="loadingConfig"
        @click="showNewStudentModalClick"
        class="
          px-3
          py-2
          bg-gray-800
          text-white text-xs
          font-bold
          uppercase
          rounded
          bg-indigo-600
          hover:bg-indigo-700
          focus:ring-indigo-500 focus:ring-offset-indigo-200
          transition
          ease-in
          duration-200
          font-semibold
          shadow-md
          focus:outline-none focus:ring-2 focus:ring-offset-2
        "
      >
        Novo aluno
      </button>
    </div>
    <StudentsTable
      v-if="config"
      :students="students"
      :loading="loadingStudents"
      :getSeriesName="getSeriesName"
      :getCycleName="getCycleName"
      :emitEditStudent="onEmitEditStudent"
    />
  </div>

  <div
    v-if="showNewStudentModal"
    class="
    modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div
          class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          "
        >
          {{ operation === "add" ? "Novo aluno" : "Editar aluno" }}
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="addingStudent"
                  id="create-student"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="name"
                  v-model="name"
                  placeholder="Nome"
                  @blur="validateInput('name')"
                />
                <small v-if="errors.name" id="nameError" class="text-red-500">
                  {{ errors.name }}
                </small>
              </div>
            </div>

            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <Multiselect
                  placeholder="Série"
                  v-model="series"
                  :options="seriesArray"
                  :searchable="true"
                  class="multiselect-purple"
                  :canDeselect="false"
                  @blur="validateInput('series')"
                ></Multiselect>
                <small
                  v-if="errors.series"
                  id="seriesError"
                  class="text-red-500"
                >
                  {{ errors.series }}
                </small>
              </div>
            </div>

            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <Multiselect
                  placeholder="Ciclo"
                  v-model="cycle"
                  :options="cyclesArray"
                  :searchable="true"
                  class="multiselect-purple"
                  :canDeselect="false"
                  @blur="validateInput('cycle')"
                ></Multiselect>
                <small v-if="errors.cycle" id="cycleError" class="text-red-500">
                  {{ errors.cycle }}
                </small>
              </div>
            </div>
          </form>
        </div>

        <div class="flex flex-col mb-2">
          <button
            v-if="operation === 'edit'"
            type="button"
            :disabled="deletingStudent"
            @click="onDeleteStudent"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            "
          >
            <svg
              v-if="deletingStudent"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-if="!deletingStudent">Excluir</span>
          </button>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            :disabled="this.errors.name || addingStudent"
            @click="onNewStudent"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            "
          >
            <svg
              v-if="addingStudent"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-if="!addingStudent">{{
              operation === "add" ? "Criar" : "Atualizar"
            }}</span>
          </button>
          <button
            type="button"
            @click="onCloseNewStudentModal"
            class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import Firebase from "@/firebase";
import StudentsTable from "@/components/StudentsTable";
import Swal from "sweetalert2";

export default {
  components: { StudentsTable },
  setup() {
    const loadingConfig = ref(false);
    const config = ref(null);

    const seriesArray = [];
    const cyclesArray = [];

    const getConfig = async () => {
      loadingConfig.value = true;
      const configSnapshot = await Firebase.getDoc("config/prod");
      config.value = configSnapshot.data();

      config.value.series.forEach((serie) =>
        seriesArray.push({
          value: serie,
          label: serie.nome,
        })
      );

      config.value.ciclos.forEach((cycle) =>
        cyclesArray.push({
          value: cycle,
          label: cycle.nome,
        })
      );

      loadingConfig.value = false;
    };

    const loadingStudents = ref(false);
    const students = ref([]);

    // const studentsPaged = ref([]);

    const getStudents = async () => {
      loadingStudents.value = true;
      const studentsSnapshots = await Firebase.getDocs("alunos");
      students.value = studentsSnapshots.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      students.value.sort((a, b) => a.nome.localeCompare(b.nome));
      loadingStudents.value = false;
    };

    const addingStudent = ref(false);

    const addStudent = async (student) => {
      addingStudent.value = true;
      const studentRef = await Firebase.addDoc("alunos", student);
      student.id = studentRef.id;
      students.value.push(student);
      students.value.sort((a, b) => a.nome.localeCompare(b.nome));
      addingStudent.value = false;
    };

    const updateStudent = async (student) => {
      addingStudent.value = true;

      const id = student.id;
      delete student.id;

      await Firebase.setDoc(`alunos/${id}`, student);

      students.value = students.value.map((s) =>
        s.id === id ? { ...s, ...student } : s
      );

      addingStudent.value = false;
    };

    const deletingStudent = ref(false);

    const deleteStudent = async (id) => {
      deletingStudent.value = true;
      await Firebase.deleteDoc(Firebase.collections.STUDENTS + "/" + id);
      students.value = students.value.filter((s) => s.id !== id);
      deletingStudent.value = false;
    };

    const errors = reactive({
      name: null,
      cycle: null,
      series: null,
    });

    return {
      students,
      addStudent,
      addingStudent,
      loadingStudents,
      getStudents,
      loadingConfig,
      config,
      getConfig,
      errors,
      seriesArray,
      cyclesArray,
      deleteStudent,
      deletingStudent,
      updateStudent,
    };
  },
  async mounted() {
    await this.getConfig();
    await this.getStudents();
  },

  data() {
    return {
      showBackdrop: false,
      showNewStudentModal: false,
      name: "",
      operation: "",
      series: null,
      cycle: null,
      studentBeingEditedId: null,
    };
  },
  methods: {
    validateInput(formName) {
      if (formName === "name") {
        if (!this.name || this.name.length < 3) {
          this.errors.name =
            "Insira um nome de no mínimo 3 caracteres para o aluno";
        } else {
          this.errors.name = false;
        }
      }

      if (formName === "series") {
        if (!this.series || this.series.length === 0) {
          this.errors.series = "Selecione uma série";
        } else {
          this.errors.series = false;
        }
      }

      if (formName === "cycle") {
        if (!this.cycle || this.cycle.length === 0) {
          this.errors.cycle = "Selecione um ciclo";
        } else {
          this.errors.cycle = false;
        }
      }
    },

    async onDeleteStudent() {
      Swal.fire({
        title: "Você tem certeza?",
        text: `Você realmente quer excluir o aluno '${this.name}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não, cancelar",
        confirmButtonText: "Sim, excluir",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteStudent(this.studentBeingEditedId);
          this.onCloseNewStudentModal();
        }
      });
    },

    async onNewStudent() {
      this.validateInput("name");
      this.validateInput("series");
      this.validateInput("cycle");

      if (this.errors.name || this.errors.series || this.errors.cycle) {
        return;
      }

      const student = {
        nome: this.name,
        serie: this.series.id,
        ciclo: this.cycle.id,
      };

      if (!student.serie && this.series?.value) {
        student.serie = this.series.value.id;
      }

      if (!student.ciclo && this.cycle?.value) {
        student.ciclo = this.cycle.value.id;
      }

      if (this.operation === "edit") {
        student.id = this.studentBeingEditedId;
        await this.updateStudent(student);
      }

      if (this.operation === "add") {
        await this.addStudent(student);
      }

      this.onCloseNewStudentModal();
    },

    showNewStudentModalClick() {
      this.operation = "add";
      this.onShowNewStudentModal();
    },

    onShowNewStudentModal() {
      this.showNewStudentModal = true;
      this.showBackdrop = true;
    },
    onCloseNewStudentModal() {
      this.showNewStudentModal = false;
      this.showBackdrop = false;
      this.name = "";
      this.series = null;
      this.cycle = null;
    },
    getSeriesName(seriesId) {
      const s = this.seriesArray.find((serie) => serie.value.id === seriesId);
      return s?.value?.nome || "Não informado";
    },
    getCycleName(cycleId) {
      const c = this.cyclesArray.find((cycle) => cycle.value.id === cycleId);
      return c?.value?.nome || "Não informado";
    },
    onEmitEditStudent(student) {
      this.studentBeingEditedId = student.id;
      this.name = student.nome;

      const s = this.seriesArray.findIndex(
        (serie) => serie.value.id === student.serie
      );
      const c = this.cyclesArray.findIndex(
        (cycle) => cycle.value.id === student.ciclo
      );

      this.series = this.seriesArray[s];
      this.cycle = this.cyclesArray[c];

      this.operation = "edit";
      this.onShowNewStudentModal();
    },
  },
};
</script>

<style scoped>
.multiselect-purple {
  --ms-ring-width: 2px;
  --ms-ring-color: #8c54ec;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-bg-selected: #ccb0fa;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-bg-selected-pointed: #b48cf4;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-color-selected: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-color-selected-disabled: #d1fae5;
}

.modal {
  position: fixed !important;
  height: auto !important;
  top: 100px !important;
  left: 0 !important;
  right: 0 !important;
}
</style>